import * as React from 'react';
import NoWalletModal from '../components/NoWalletModal';

const ConnectWallet = () => {
  return (
    <>
      <NoWalletModal />
    </>
  );
};

export default ConnectWallet;
