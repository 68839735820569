import { navigate } from 'gatsby';
import * as React from 'react';
import { useWeb3Context } from '../hooks/useWeb3Context';

interface SimpleContentProps {
  titleModal: string;
  textModal1: string;
  textModal2: string;
  imageModal: string;
  urlLink?: string;
  titleButton: string;
  state?: any;
  closeModal?: () => void;
}

const SimpleContent = ({
  titleModal,
  textModal1,
  textModal2,
  imageModal,
  urlLink,
  state,
  titleButton,
  closeModal
}: SimpleContentProps) => {
  const { connectWallet } = useWeb3Context();

  const buttonHandler = async () => {
    switch (titleModal) {
      case 'Connect your wallet':
        connect();
        return;
      case 'You currently do not own any Paima NFTs':
        navigate('/nft-collection');
        return;
    }
  };

  const connect = async () => {
    await connectWallet();
    if (closeModal) closeModal();
  };

  return (
    <>
      <div className="w-full md:w-[392px] container z-40 bg-modal p-16">
        <div className="bg-modal-content flex flex-col justify-center items-center p-28">
          <h2 className="modal-title mb-22">{titleModal}</h2>
          <p className="modal-text"> {textModal1}</p>
          <p className="modal-text mb-18">{textModal2}</p>
          <img className="w-[225px] h-[220px]" src={imageModal} width="225" height="220" />
          <button className="btn-big w-full md:w-[252px] mt-18" onClick={buttonHandler}>
            {titleButton}
          </button>
        </div>
      </div>
    </>
  );
};

export default SimpleContent;
