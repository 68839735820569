import React from 'react';
import Modal from 'react-modal';
import * as Analytics from '../services/analytics-utils';
import SimpleContent from './SimpleContent';

const customStyles = {
  overlay: {
    zIndex: '100',
    backgroundColor: 'rgba(0, 54, 35, 0.85)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    border: 'none'
  }
};

interface NoWalletModalProps {
  button: string;
  classname?: string;
  urlLink?: string;
}

const NoWalletModal = ({ button, classname, urlLink }: NoWalletModalProps) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
    Analytics.event('Buy NFT');
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <button onClick={openModal} className={classname} style={{ width: '100%' }}>
        {button}
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Connect your wallet modal"
      >
        <div>
          <SimpleContent
            titleModal="Connect your wallet"
            textModal1=""
            textModal2="Connect your wallet to buy and explore Paima NFTs"
            imageModal="/images/modal-no-wallet.png"
            titleButton="Connect Wallet"
            closeModal={closeModal}
          />
        </div>
      </Modal>
    </>
  );
};

export default NoWalletModal;
